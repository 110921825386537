* {
  scrollbar-width: thin;
  scrollbar-color: #e1dfdd #faf9f8;
}
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: #faf9f8;
}
*::-webkit-scrollbar-thumb {
  background-color: #e1dfdd;
  border-radius: 20px;
  border: 3px solid #faf9f8;
}
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

body {
  background: #faf9f8;
  margin: 0px;
}
.ms-Nav {
  background-color: #f3f2f1;
  margin-left: -8px;
}

.ms-Grid-col {
  padding-left: 0;
  margin-left: 0;
}

.dialog-class {
  max-width: 133vh;
}
.btnLikeAnchor {
  cursor: pointer;
  max-width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: none;
  background-color: transparent;
  border: none;
  color: #0366d6;
  font-family: 'SegoeUI-Regular-final,Segoe UI, Segoe, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, Tahoma, Helvetica, Arial, sans-serif';
  font-size: 12px;
}

.quickViewLink {
  z-index: 1;
}
.environmentLinkCss:focus {
  outline: #757070 1px auto !important;
  box-shadow: #757070 0px 0px 0px 1px inset !important;
}
.quickViewLink:focus {
  outline: #757070 1px auto !important;
  box-shadow: #757070 0px 0px 0px 1px inset !important;
}

@media screen and (-ms-high-contrast: active) and (forced-colors: active) {
  .btnLikeAnchor {
    color: linktext;
    forced-color-adjust: none;
  }
}
