.product-container-hover:hover {
  border: 2px solid #0078d4 !important;
}
.headerTitle {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  text-overflow: ellipsis;
  max-width: 225px;
  overflow-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}
